import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import {Form, FormGroup, Label, Button, Col, Input, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import { useParams } from 'react-router-dom';
import './CreateOrder.css';
import Layout from '../Layout/Layout';

const CreateOrder = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const user = JSON.parse(localStorage.getItem('user'));
    const [fieldDisabled, setFieldDisabled] = useState(user.roleId === 3);
    const [previousState, setPreviousState] = useState(null);

    const [order, setOrder] = useState({
        name: '',
        address: '',
        phoneNumber: '',
        productName: '',
        quantity: '',
        price: '',
        valley: 'Inside',
        userId: user.id,
        orderStatusId: 1,
        comment: '',
        csComment: '',
        pageName: '',
        statusModifiedBy: user.id,
        courier: ''
    });

    useEffect(() => {
        if (id) {
            setLoading(true);
            getEditOrder();
        }
    }, []);

    const getEditOrder = async () => {
        const response = await axios.get(`api/order/${id}`);
        const data = await response.data;
        setOrder(data);
        setPreviousState(data);
        setLoading(false);
    }

    const [modal, setModal] = useState(false);

    const toggle = () => {
        setModal(!modal);
        setOrder({
            name: '',
            address: '',
            phoneNumber: '',
            productName: '',
            quantity: '',
            price: '',
            valley: 'Inside',
            comment: '',
            csComment: '',
            pageName: '',
            courier: ''
        });
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setOrder({ ...order, [name]: value });
    };

    const validateForm = () => {
        if (user.roleId == 3) {
            return true;
        }
        if (order.phoneNumber.length !== 10) {
            document.getElementById('phone-error-msg').innerHTML = "Enter valid phone number";
            return false;
        } else {
            document.getElementById('phone-error-msg').innerHTML = '';
        }

        var keys = ["name", "address", "pageName", "productName", "quantity", "price", "valley"];
        keys.forEach((key) => {
            if (order[key] === '') {
                document.getElementById('error-msg').innerHTML = "Please fill up all the details";
                return false;
            }
        })
        return true;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            if (id) {
                if (previousState.orderStatusId !== order.orderStatusId) {
                    order.statusModifiedBy = user.id;
                }
                await axios.put('api/order', order)
                    .then(function (response) {
                        console.log(response);
                        toggle();
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            } else {
                await axios.post('api/order', order)
                    .then(function (response) {
                        console.log(response);
                        toggle();
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
        }
    };

    return (
        <Layout>
            <h2>{ id ? `Edit Order` : `Create New Order`}</h2>

            {
                loading ? <p>Loading...</p> : 
                    <Form onSubmit={handleSubmit}>
                        <FormGroup row>
                            <Label sm={2} > Name </Label>
                            <Col sm={4}> <Input type="text" name="name" value={order.name} onChange={handleInputChange} disabled={fieldDisabled} /> </Col>
                        </FormGroup>

                        <FormGroup row>
                            <Label sm={2} > Address </Label>
                            <Col sm={4}> <Input type="text" name="address" value={order.address} onChange={handleInputChange} disabled={fieldDisabled} /> </Col>
                        </FormGroup>

                        <FormGroup row>
                            <Label sm={2} > Phone Number </Label>
                            <Col sm={4}>
                                <Input type="number" name="phoneNumber" value={order.phoneNumber} onChange={handleInputChange} disabled={fieldDisabled} />
                                <span id="phone-error-msg" style={{ color: "red" }}></span>
                            </Col>
                        </FormGroup>

                        <FormGroup row>
                            <Label sm={2} > Page </Label>
                            <Col sm={4}> <Input type="text" name="pageName" value={order.pageName} onChange={handleInputChange} disabled={fieldDisabled} /> </Col>
                        </FormGroup>

                        <FormGroup row>
                            <Label sm={2} > Product Name </Label>
                            <Col sm={4}> <Input type="text" name="productName" value={order.productName} onChange={handleInputChange} disabled={fieldDisabled} /> </Col>
                        </FormGroup>

                        <FormGroup row>
                            <Label sm={2} > Quantity </Label>
                            <Col sm={4}> <Input type="number" name="quantity" value={order.quantity} onChange={handleInputChange} disabled={fieldDisabled} /> </Col>
                        </FormGroup>

                        <FormGroup row>
                            <Label sm={2} > Price </Label>
                            <Col sm={4}> <Input type="number" name="price" value={order.price} onChange={handleInputChange} disabled={fieldDisabled} /> </Col>
                        </FormGroup>

                        <FormGroup row>
                            <Label sm={2} > Valley </Label>
                            <Col sm={4}>
                                <Input type="select" name="valley" value={order.valley} onChange={handleInputChange} >
                                    <option>Inside</option>
                                    <option>Outside</option>
                                </Input>
                            </Col>
                        </FormGroup>

                        {user.roleId === 3 ?
                            <>
                            <FormGroup row>
                                <Label sm={2} > Status </Label>
                                <Col sm={4}>
                                    <Input type="select" name="orderStatusId" value={order.orderStatusId} onChange={handleInputChange}>
                                        <option value='1'>Order Created</option>
                                        <option value='2'>Follow Up</option>
                                        <option value='3'>Ready To Ship</option>
                                        <option value='4'>Dispatched</option>
                                        <option value='5'>Canceled</option>
                                        <option value='6'>Returned</option>
                                    </Input>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={2} > Courier </Label>
                                <Col sm={4}>
                                    <Input type="select" name="courier" value={order.courier} onChange={handleInputChange}>
                                        <option></option>
                                        <option >Nepacart</option>
                                        <option >Aarambha</option>
                                        <option >Nepal CAN</option>
                                        <option >Sanish</option>
                                        <option >Purushottam</option>
                                        <option >ThuExpress</option>
                                    </Input>
                                </Col>
                            </FormGroup>
                            </>
                            : ''}

                        <FormGroup row>
                            <Label sm={2} > Comment </Label>
                            <Col sm={4}> <Input type="text" name="comment" value={order.comment} onChange={handleInputChange} disabled={fieldDisabled} /> </Col>
                        </FormGroup>

                        <FormGroup row>
                            <Label sm={2} > CSComment </Label>
                            <Col sm={4}> <Input type="text" name="csComment" value={order.csComment} onChange={handleInputChange} disabled={user.roleId != 3} /> </Col>
                        </FormGroup>

                        <FormGroup row>
                            <Col sm={4}><span id="error-msg" style={{color: "red"} }></span>  </Col>
                        </FormGroup>

                        <FormGroup check row >
                            <Col sm={{ offset: 2, size: 10 }} > <Button type="submit"> {id ? `Update Order` : `Create Order`} </Button> </Col>
                        </FormGroup>

                    </Form>
            }
            

            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>{ id ? 'Order Updated' : 'Order Placed' }</ModalHeader>
                <ModalBody>
                    Your Order has been {id ? 'updated' : 'placed'}.
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={toggle}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        </Layout>
    );
}

export default CreateOrder;
