import Order from "./components/Order/Order";
import CreateOrder from "./components/CreateOrder/CreateOrder";
import Print from "./components/Print/Print";
import Login from "./components/Login/Login";
import CreateUser from "./components/User/CreateUser";
import UserList from "./components/User/UserList";
import Scanner from "./components/Scanner/Scanner";

const AppRoutes = [
  {
    index: true,
    path: '/login',
    element: <Login />
  },
  {
    path: '/',
    element: <Order />
  },
  {
      path: '/create-order',
    element: <CreateOrder />
    },
    {
        path: '/edit/:id',
        element: <CreateOrder />
    },
    {
        path: '/print',
        element: <Print />
    },
    {
        path: '/users',
        element: <UserList />
    },
    {
        path: '/create-user',
        element: <CreateUser />
    },
    {
        path: '/edit-user/:id',
        element: <CreateUser />
    },
    {
        path: '/scanner',
        element: <Scanner />
    }

];

export default AppRoutes;
