import React, { useState, useEffect } from 'react';
import { Button, Form, FormGroup, Col, Input, Container, Row, Card, CardBody, CardTitle } from 'reactstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Login.css'; 

const Login = () => {
    const [organization, setOrganization] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        if (localStorage.getItem('loggedIn')) {
            navigate('/');
        }
        localStorage.clear();
    }, []);

    const onSubmit = async (e) => {
        e.preventDefault();
        var data = {
            'organization': organization,
            'username': username,
            'password': password
        }
        await axios.post('/api/login', data)
            .then((response) => {
                if (response.data.success === true && response.data.user.status == 1) {
                    localStorage.setItem('user', JSON.stringify(response.data.user));
                    localStorage.setItem('loggedIn', 1);
                    navigate('/');
                } else {
                    document.getElementById('error-msg').innerHTML = "Invalid username or password";
                }
            })
            .catch((response) => {
                console.log('login unsuccessful');
            })
    }

    return (
        <div className="login-container">
            <Row className="justify-content-center">
                <Card className="login-card">
                    <CardBody>
                        <CardTitle tag="h3" className="text-center">Welcome</CardTitle>
                        <Form onSubmit={onSubmit}>
                            <FormGroup row>
                                <Col sm={12}>
                                    <Input
                                        type="text"
                                        name="organization"
                                        placeholder="Organization"
                                        onChange={e => setOrganization(e.target.value)}
                                        required
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col sm={12}>
                                    <Input
                                        type="text"
                                        name="username"
                                        placeholder="Username"
                                        onChange={e => setUsername(e.target.value)}
                                        required
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col sm={12}>
                                    <Input
                                        type="password"
                                        name="password"
                                        placeholder="Password"
                                        onChange={e => setPassword(e.target.value)}
                                        required
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col sm={12}>
                                    <span id="error-msg" className="error-msg"></span>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col sm={12}>
                                    <Button color="primary" type="submit" block>Login</Button>
                                </Col>
                            </FormGroup>
                        </Form>
                    </CardBody>
                </Card>
            </Row>
        </div>
    )
}

export default Login;