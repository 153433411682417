import React, { useState, useEffect } from 'react';
import { Form, FormGroup, Input, Button, Label, Col, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import Layout from '../Layout/Layout';


const CreateUser = () => {
    const { id } = useParams();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [role, setRole] = useState(1);
    const [status, setStatus] = useState(1);
    const [modal, setModal] = useState(false);
    const navigate = useNavigate();
    const user = JSON.parse(localStorage.getItem('user'));

    useEffect(() => {
        if (id) {
            getEditUser();
        }
    }, []);

    const getEditUser = async () => {
        const response = await axios.get(`api/user/${id}`);
        const data = await response.data;
        setUsername(data.name);
        setPassword(data.password);
        setPhoneNumber(data.phoneNumber);
        setRole(data.roleId);
        setStatus(data.status);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        var data = {
            'organizationId': user.organizationId,
            'name': username,
            'password': password,
            'roleId': role,
            'phoneNumber': phoneNumber,
            'status': status
        }

        if (id) {
            data["id"] = id;
            await axios.put('/api/user', data)
                .then((response) => {
                    console.log(response);
                    setModal(!modal);
                })
                .catch((response) => {

                })
        }else {
            await axios.post('/api/user', data)
                .then((response) => {
                    console.log(response);
                    setModal(!modal);
                })
                .catch((response) => {

                })
        }  
    }

    const toggle = () => {
        setModal(!modal);
        navigate('/users');
    }

    return (
        <Layout>
            <h2 >{id ? `Edit User` : `Create New User`}</h2>

            <Form onSubmit={handleSubmit}>
                <FormGroup row>
                    <Label sm={2} > Username </Label>
                    <Col sm={4}> <Input type="text" name="name" value={username} onChange={e => setUsername(e.target.value)} /> </Col>
                </FormGroup>

                <FormGroup row>
                    <Label sm={2} > Password </Label>
                    <Col sm={4}> <Input type="password" name="address" value={password} onChange={e => setPassword(e.target.value)} /> </Col>
                </FormGroup>

                <FormGroup row>
                    <Label sm={2} > Phone Number </Label>
                    <Col sm={4}>
                        <Input type="number" name="phoneNumber" value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} />
                        <span id="phone-error-msg" style={{ color: "red" }}></span>
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Label sm={2} > Role </Label>
                    <Col sm={4}>
                        <Input type="select" name="role" value={role} onChange={e => setRole(e.target.value)} >
                            <option value='1'>Admin</option>
                            <option value='2'>Chat Person</option>
                            <option value='3'>Customer Support</option>
                        </Input>
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Label sm={2} > Status </Label>
                    <Col sm={4}>
                        <Input type="select" name="status" value={status} onChange={e => setStatus(e.target.value)} >
                            <option value='1'>Active</option>
                            <option value='2'>Inactive</option>
                        </Input>
                    </Col>
                </FormGroup>

                <FormGroup check row >
                    <Col sm={{ offset: 2, size: 10 }} > <Button> {id ? `Update` : `Create`} </Button> </Col>
                </FormGroup>

            </Form>

            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>User {id ? `Updated`: `Created`}</ModalHeader>
                <ModalBody>
                    {id ? `User has been updated` : `New User has been created.`}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={toggle}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        </Layout>
    )
}

export default CreateUser;