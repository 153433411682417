import React, { useEffect, useState } from 'react';
import { Table, Button, Input } from 'reactstrap'
import { Link } from 'react-router-dom';
import axios from 'axios';
import Layout from '../Layout/Layout';

const UserList = () => {
    const [userList, setUserList] = useState([]);

    useEffect(() => {
        getUsers()
    }, []);


    const getUsers = async () => {
        const response = await axios.get('/api/user');
        setUserList(response.data);
    }

    return (
        <Layout>
            <h2 id="tableLabel" >Users</h2>
            <Link to="/create-user"><Button color="primary" className="margin-10">Create New User</Button></Link>

            <Table hover responsive>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Phone Number</th>
                        <th>Role</th>
                        <th>Status</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {userList.map(user =>
                        <tr key={user.id}>
                            <td>{user.name}</td>
                            <td>{user.phoneNumber}</td>
                            <td>{user.roleId === 1 ? `Admin` : (user.roleId == 2 ? `Chat Person` : `Customer Support`)}</td>
                            <td>{user.status === 1 ? `Active` : `Inactive`}</td>
                            <td><Link to={`/edit-user/${user.id}`}><Button color="primary">Edit</Button></Link></td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </Layout>

    );
}

export default UserList;
