import React, { useState, useEffect } from 'react';
import { FormGroup, Input, Label, Form, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Layout from '../Layout/Layout';


const Scanner = () => {
    const [scannedIDs, setScannedIDs] = useState('');
    const [orderStatusId, setOrderStatusId] = useState();
    const [courier, setCourier] = useState('');

    const [modal, setModal] = useState(false);
    const [modalMsg, setModalMsg] = useState("");
    const navigate = useNavigate();
    const user = JSON.parse(localStorage.getItem('user'));

    useEffect(() => {
        if (!localStorage.getItem('loggedIn')) {
            navigate('/login');
        }
    }, []);

    const toggle = () => {
        setModal(!modal);
    }

    const closeModal = () => {
        toggle();
        navigate('/');
    }


    const onSubmit = async (e) => {
        e.preventDefault();
        var data = {
            'ids': scannedIDs.toUpperCase().trim().replaceAll('EG', '').split('\n'),
            'orderStatusId': orderStatusId,
            'courier': courier,
            'userId': user.id
        };

        await axios.post('/api/Order/update', data)
            .then((response) => {
                setModalMsg(response.data.message);
                toggle();
            })
            .catch((response) => {
                setModalMsg(response.data.message);
                toggle();
            })

    }

    return (
        <Layout>
            <h2 id="tableLabel" >Scanner</h2>

            <Form onSubmit={onSubmit}>
                <FormGroup >
                    <Label>
                        Scanned Ids
                    </Label>

                    <Input style={{ width: "500px", height: "300px" }}
                        name="text"
                        type="textarea"
                        value={scannedIDs}
                        onChange={e => setScannedIDs(e.target.value)}
                    />
                </FormGroup>
                <FormGroup>
                    <Col sm={4}>
                        <Label>
                            Order Status
                        </Label>
                        <Input type="select" name="orderStatusId" value={orderStatusId} onChange={e => setOrderStatusId(e.target.value)}>
                            <option></option>
                            <option value='1'>Order Created</option>
                            <option value='2'>Follow Up</option>
                            <option value='3'>Ready To Ship</option>
                            <option value='4'>Dispatched</option>
                            <option value='5'>Canceled</option>
                            <option value='6'>Returned</option>
                        </Input>
                    </Col>
                </FormGroup>
                <FormGroup>
                    <Col sm={4}>
                        <Label>
                            Courier
                        </Label>
                        <Input type="select" name="courier" value={courier} onChange={e => setCourier(e.target.value)}>
                            <option></option>
                            <option >Nepacart</option>
                            <option >Aarambha</option>
                            <option >Nepal CAN</option>
                            <option >Sanish</option>
                            <option >Purushottam</option>
                            <option >ThuExpress</option>
                        </Input>
                    </Col>
                </FormGroup>
                <FormGroup row >
                    <Col sm={4}>
                        <Button color="primary" type="submit"> Update </Button>
                    </Col>
                </FormGroup>
            </Form>
            <Modal isOpen={modal} toggle={closeModal}>
                <ModalHeader toggle={closeModal}>Orders Update</ModalHeader>
                    <ModalBody>
                        {modalMsg}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={closeModal}>Close</Button>
                    </ModalFooter>
            </Modal>
        </Layout>
    )
}

export default Scanner;