import React from 'react';
import {Nav, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './Sidebar.css';

const Sidebar = () =>  {
    const user = JSON.parse(localStorage.getItem('user'));


        return (
            <div className="sidebar" >
                <Nav>
                    <ul className="navbar-nav menu-items">
                        <h5>Elegant Gahana</h5>

                        {
                            user ? <div style={{ textAlign: 'center', color: '#23ff00' }}> Hi {user.name} </div>
                                : ''
                        }

                        <NavItem>
                            <NavLink tag={Link} to="/">Order</NavLink>
                        </NavItem>


                        {user ? user.roleId === 1 ?
                            <NavItem>
                                <NavLink tag={Link} to="/users">User</NavLink>
                            </NavItem>
                            : '' : ''
                        }

                        {user ? user.roleId !== 2 ?
                            <NavItem>
                                <NavLink tag={Link} to="/scanner">Scanner</NavLink>
                            </NavItem>
                            : '' : ''
                        }

                        <NavItem>
                            <NavLink tag={Link} to="/login">Logout</NavLink>
                        </NavItem>
                    </ul>
                </Nav>
            </div>
        );
    
}

export default Sidebar;
