import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import axios from 'axios';
import './Print.css';
import '../../common.css';
import Barcode from 'react-barcode';
import Layout from '../Layout/Layout';


const Print = () => {

    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [modal, setModal] = useState(false);


    useEffect(() => {
        getAllOrders();
    },[]);

    const getAllOrders = async () => {
        const response = await axios.get('api/order/print');
        const data = await response.data;
        setOrders(data);
        setLoading(false);
    }

    const handlePrint = () => {
        window.print();
        window.onafterprint = () => {
            console.log('printing completed');
        }
    }

    const printOrders = async () => {
        const response = await axios.get('api/order/dispatch');
        if (response.data.success === true) {
            document.getElementById('printed-msg').innerHTML = "All orders are Ready To Ship";
            document.getElementById('print-button').style.display = 'none';
        }
    }

    const toggle = () => {
        setModal(!modal);
    }

    return (
        <Layout>
            {loading ? <p>Loading...</p> : 
            <div>
                <h2>PDF Generator</h2>

                <div className="download-buttons">
                        <Button color="primary" className="margin-10" onClick={() => handlePrint()}>Download PDF</Button>
                        <Button className="float-right margin-10 background-danger" onClick={toggle}>Ready to Ship</Button>
                </div>

                <div id="content-to-pdf">
                        {orders.map(order =>
                            (order.orderStatusId === 1 && order.valley === "Inside") &&
                            <div key={order.id }>
                            <table className="table" key={order.id}>
                                <tr>
                                    <td className="title" colSpan='3' rowSpan='5'>
                                                {/*Elegant Tisa<br></br> Mahapal-16, Lalitpur, Nepal <br></br>9840466867<br></br>*/}
                                                <Barcode value={'EG'+order.id} />
                                    </td>
                                    <td>Date</td>
                                    <td colSpan='2'>{new Date(order.createdDate).toLocaleDateString()}</td>
                                </tr>
                                <tr>
                                    <td>Name</td>
                                    <td colSpan='2'>{ order.name }</td>
                                </tr>
                                <tr>
                                    <td>Address</td>
                                    <td colSpan='2'>{ order.address }</td>
                                </tr>
                                <tr>
                                    <td>Mobile No.</td>
                                    <td colSpan='2'>{ order.phoneNumber }</td>
                                </tr>
                                <tr>
                                    <td>Page Name</td>
                                    <td colSpan='2'>{ order.pageName }</td>
                                </tr>
                                <tr>
                                    <th colSpan="3">Product Details</th>
                                    <th>Quantity</th>
                                    <th>Amount</th>
                                </tr>
                                    <tr>
                                        <td colSpan="3">{order.productName}</td>
                                    <td>{ order.quantity }</td>
                                    <td>{ order.price}</td>
                                </tr>
                                <tr>
                                        <td colSpan='1'>Total Amount</td>
                                        <td style={{ textAlign: 'center', fontWeight: 'bold' }} colSpan='5'>NRS. { order.price }</td>
                                </tr>
                            </table>
                                <div className="pagebreak"></div>
                            </div>
                        )}
                    
                </div>
            </div>
            }

            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader className="color-red" toggle={toggle}>Warning</ModalHeader>
                <ModalBody>
                    Make sure bills have been saved.
                    <br></br>
                    If not you will have to manually revert all items back.
                    <br></br>
                    <span className="color-red">Are you sure you are Ready To Ship?</span>
                    <br></br>
                    <br></br>
                    <span id="printed-msg"></span>
                </ModalBody>
                <ModalFooter>
                    <Button id="print-button" className="background-danger" onClick={printOrders}>Ready To Ship</Button>
                    <Button color="primary" onClick={toggle}>Close</Button>
                </ModalFooter>
            </Modal>
        </Layout>
    );
};

export default Print;
