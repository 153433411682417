import React, { useEffect, useState } from 'react';
import { Table, Button, Input } from 'reactstrap'
import { Link } from 'react-router-dom';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import '../../common.css';
import axios from 'axios';


const OrderList = () => {
    const [loading, setLoading] = useState(true);
    const user = JSON.parse(localStorage.getItem('user'));
    const [orders, setOrders] = useState([]);
    const [paginationIndex, setPaginationIndex] = useState(1);
    const pageSize = 25;
    const [filters, setFilters] = useState({
        phoneNumber: '',
        createdBy: '',
        valley: '',
        orderStatusId: '',
        createdDate: '',
        courier: '',
        orderId: ''
    });
    const [filter, setFilter] = useState(false);

    useEffect(() => {
        async function getData() {
            const data = await populateOrderData();
            setOrders(data);
            setLoading(false);
        }
        setLoading(true);
        getData();
    }, [paginationIndex, filter]);

    const populateOrderData = async (exportData = false) => {
        const response = await axios.get('/api/Order',
            {
                params: {
                    phoneNumber: filters.phoneNumber,
                    createdBy: filters.createdBy,
                    valley: filters.valley,
                    orderStatusId: filters.orderStatusId,
                    createdDate: filters.createdDate,
                    courier: filters.courier,
                    orderId: filters.orderId,
                    pageNumber: paginationIndex,
                    userId: user.roleId === 2 ? user.id : 0,
                    export: exportData
                }
            });
        return await response.data;
    }

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters({ ...filters, [name]: value });
    };

    const updatePagination = (changeValue) => {
        if (paginationIndex === 1 && changeValue === -1) {
            return;
        }
        if (changeValue === 1 && orders.length < pageSize) {
            return;
        }
        setPaginationIndex(paginationIndex + changeValue);
    }

    const exportToExcel = async () => {
        const data = await populateOrderData(true);
        const workbook = XLSX.utils.book_new();

        const worksheet = XLSX.utils.json_to_sheet(data);

        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const dataBlob = new Blob([excelBuffer], { type: 'application/octet-stream' });

        saveAs(dataBlob, new Date().toDateString() + `.xlsx`);
    };

    const applyFilters = () => {
        setFilter(!filter);
    }


    return (
            <>
            {loading ? <p>Loading.....</p> :
                <div>
                    <h2 id="tableLabel" >Orders</h2>
                    <div>
                        <div>
                            {user.roleId !== 3 ?
                                <Link to="/create-order"><Button color="primary" className="margin-10">Create New Order</Button></Link>
                                : ''
                            }
                            {user.name === 'printer'
                                ? <Link to="/print"><Button color="primary">Print</Button></Link>
                                : ''}
                            <Button color="primary" className="margin-10" onClick={() => exportToExcel()}>Export</Button>
                        </div>
                        <div className="display-flex margin-10">
                            <Input type='text' name="orderId" value={filters.orderId} placeholder="Order Id" onChange={handleFilterChange} />
                            <Input type='number' name="phoneNumber" value={filters.phoneNumber} placeholder="Phone Number" onChange={handleFilterChange} />
                            {user.roleId !== 2
                                ? <Input type='text' name="createdBy" value={filters.createdBy} placeholder="Created By" onChange={handleFilterChange} />
                                : ''}
                            <Input type="select" name="valley" value={filters.valley} onChange={handleFilterChange} >
                                <option></option>
                                <option>Inside</option>
                                <option>Outside</option>
                            </Input>

                            <Input
                                name="createdDate"
                                placeholder="date placeholder"
                                type="date"
                                value={filters.createdDate}
                                onChange={handleFilterChange}
                            />

                            <Input type="select" name="orderStatusId" value={filters.orderStatusId} onChange={handleFilterChange}>
                                <option></option>
                                <option value='1'>Order Created</option>
                                <option value='2'>Follow Up</option>
                                <option value='3'>Ready To Ship</option>
                                <option value='4'>Dispatched</option>
                                <option value='5'>Canceled</option>
                                <option value='6'>Returned</option>
                            </Input>

                            {user.roleId !== 2
                                ?
                                <Input type="select" name="courier" value={filters.courier} onChange={handleFilterChange}>
                                    <option></option>
                                    <option >Nepacart</option>
                                    <option >Aarambha</option>
                                    <option >Nepal CAN</option>
                                    <option >Sanish</option>
                                    <option >Purushottam</option>
                                    <option >ThuExpress</option>
                                </Input>
                                : ''}
                        </div>
                        <Button color="primary" className="margin-10" onClick={() => applyFilters()} >Filter</Button>
                    </div>


                    <Table hover responsive>
                        <thead>
                            <tr>
                                <th>SN</th>
                                <th>OrderId</th>
                                <th>Date</th>
                                <th>Page</th>
                                <th>CustomerName</th>
                                <th>CustomerAddress</th>
                                <th>Phone No.</th>
                                <th>ProductNameWithDescription</th>
                                <th>Qty</th>
                                <th>Price</th>
                                <th>Valley</th>
                                <th>Status</th>
                                <th>ChatPersonComment</th>
                                <th>CustomerSupport Comment</th>
                                <th>Cancellation Reason</th>
                                <th>Courier</th>
                                <th>Created By</th>
                                <th>Status Modified By</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {orders.map((order, index) =>
                                <tr key={order.id}>
                                    <td>{index + 1}</td>
                                    <td><Link to={`/edit/${order.id}`}>EG{order.id}</Link></td>
                                    <td>{new Date(order.createdDate).toLocaleDateString()}</td>
                                    <td>{order.pageName}</td>
                                    <td>{order.name}</td>
                                    <td>{order.address}</td>
                                    <td>{order.phoneNumber}</td>
                                    <td className="productName">{order.productName}</td>
                                    <td>{order.quantity}</td>
                                    <td>{order.price}</td>
                                    <td>{order.valley}</td>
                                    <td>{order.orderStatus.status}</td>
                                    <td>{order.comment}</td>
                                    <td>{order.csComment}</td>
                                    <td>{order.cancellationReason}</td>
                                    <td>{order.courier}</td>
                                    <td>{order.createdBy.name}</td>
                                    <td>{order.orderStatusModifiedBy.name}</td>
                                    <td><Link to={`/edit/${order.id}`}><Button color="primary">Edit</Button></Link></td>
                                </tr>
                            )}
                        </tbody>
                    </Table>

                    <div className="float-right">
                        <Button color="primary" className="margin-10" onClick={() => updatePagination(-1)}>Previous</Button>
                        <span>{paginationIndex}</span>
                        <Button color="primary" className="margin-10" onClick={() => updatePagination(1)}>Next</Button>
                    </div>
                </div>
        }</>
        );
}

export default OrderList;
