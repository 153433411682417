import React, { useState, useEffect } from 'react';
import axios from 'axios';
import OrderList from './OrderList';
import { useNavigate } from 'react-router-dom';
import Layout from '../Layout/Layout';

const Order = () => {
    const navigate = useNavigate();

    useEffect(() => {
        if (!localStorage.getItem('loggedIn')) {
            navigate('/login');
        }
    }, []);


    return (
        <Layout>
                <OrderList />
        </Layout>
    );
}

export default Order;
